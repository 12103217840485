import { boolYesNoOpts, rentalPeriodOpts, tenancyTypeOpts, tenantSaleContinueContractOpts, tenantSaleContinueOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from './CommonComponentWrappers';
import { EditorListChildProps } from './CollectionEditor';
import { SaleTenancyContinuing, TenantAgreement } from '@property-folders/contract';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import clsJn from '@property-folders/common/util/classNameJoin';

export function TenancyInputInst({ saleContractMode = false, parentPath, myPath, myIndex, hideDelete, removable }: {saleContractMode?: boolean} & EditorListChildProps) {
  const { value: tenant, fullPath, handleRemove } = useTransactionField<TenantAgreement>({ parentPath, myPath });
  const { value: tenancyList } = useLightweightTransaction<TenantAgreement[]>({ myPath: parentPath });
  const multipleTenants = (tenancyList?.length??0) > 1;

  const thisTenantContinues = tenant.tenancyCont === SaleTenancyContinuing.Yes;

  const removeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return <div className='w-100 mt-3'>
    <div className={'d-flex flex-row'}>
      {(typeof myIndex === 'number' || myIndex) && <div className='fs-5'>Tenancy {(typeof myIndex === 'string' ? parseInt(myIndex) : myIndex)+1}</div>}
      {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-auto'>
        {removeButton}
      </div>}
    </div>
    {saleContractMode && <>

      <div className='mt-2'><WrField.CheckRadio
        label='Is the existing tenancy to continue after Settlement?'
        radioType='checkbox'
        options={tenantSaleContinueContractOpts}
        valueType='string'
        inline={false}
        name='tenancyCont'
        parentPath={fullPath}
        myPath='tenancyCont'
      />
      </div>

      {tenant.tenancyCont === SaleTenancyContinuing.No && <div className='mt-2'>
          The Vendor must provide vacant possession of the Property to the Purchaser at Settlement.
      </div>}
    </>}
    <div className="d-flex w-100 flex-wrap">

    </div>
    <div className={clsJn(multipleTenants && 'ms-3')}>
      {(!saleContractMode || thisTenantContinues) && <>
        <WrField.CheckRadio
          label='Agreement type'
          radioType='checkbox'
          options={tenancyTypeOpts}
          inline={true}
          name='type'
          parentPath={fullPath}
          myPath='type'
        />
      </>}
      {((!saleContractMode && thisTenantContinues) || multipleTenants) && <div className="d-flex w-100 flex-wrap gapped-row">
        <div className='flex-grow-1'>
          <WrField.Control name='tenantName' label={'Tenant(s)'} parentPath={fullPath} myPath='tenantName'
            placeholder={'Tenant\'s Name'}/>
        </div>
      </div>}
      {(!saleContractMode || thisTenantContinues) && <>
        {!saleContractMode && <div className="d-flex w-100 flex-wrap gapped-row">
          <div className='flex-grow-1'>
            <WrField.Control name='manageAgent' label={'Managing agent'} parentPath={fullPath} myPath='manageAgent'
              placeholder='Details of managing agent'/>
          </div>
        </div>}
        <div className="d-flex w-100 flex-wrap gapped-row">
          <div className='flex-grow-1'>
            <WrField.Control name='start' type='date' useCanonical={true} label={'Commencement of tenancy'}
              parentPath={fullPath} myPath='start' placeholder='Start date'/>
          </div>

          <div className='flex-grow-1'>
            <WrField.Control name='end' type='date' dateFromToday={true} useCanonical={true} label={'End of tenancy'}
              parentPath={fullPath} myPath='end' placeholder='End date'/>
          </div>
        </div>
        <div className="d-flex w-100 flex-wrap gapped-row">
          <div className='flex-grow-0' style={{ width: '150px' }}>
            <WrField.Control name='rentalValue' label={'Rent amount'} parentPath={fullPath} myPath='rentalValue'
              placeholder='$###'/>
          </div>
          <div className='flex-grow-0 d-flex align-items-center' style={{ width: '200px' }}>
            <div className='mx-3'>per</div>
            <div className='flex-grow-1'>
              <WrField.Select
                label='Period'
                options={rentalPeriodOpts}
                name='period'
                parentPath={fullPath}
                myPath='period'
              />
            </div>
          </div>
        </div>
        <div className="d-flex w-100 flex-wrap gapped-row mt-3">
          <div className='d-flex flex-wrap flex-grow-0'>
            <div className='flex-grow-1'>
              <WrField.CheckRadio
                label='Bond with Consumer and Business Services'
                radioType='checkbox'
                options={boolYesNoOpts}
                valueType='boolean'
                inline={true}
                name='bondEnable'
                parentPath={fullPath}
                myPath='bondEnable'
              />
            </div>
            {tenant?.bondEnable && <div className='flex-grow-0 ms-3' style={{ width: '200px' }}>
              <WrField.Control name='bondAmt' label={'Bond amount'} parentPath={fullPath} myPath='bondAmt'
                placeholder='$#,###'/>
            </div>}
          </div>
        </div>

        {saleContractMode && SaleTenancyContinuing.Yes && <div>
          The Vendor or Vendor's representative is to advise the tenant of the Purchaser's name and new rent payment details at least 14 days before Settlement.
        </div>}

        {!saleContractMode && <>
          <div className='mt-2'>Vendor or Vendor's representative to advise tenant that the Property is for sale within <i className='fst-italic'>14 days</i> of this Agreement.</div>
          <div className='mt-2'><WrField.CheckRadio
            label='Is the existing tenancy to continue after Settlement?'
            radioType='checkbox'
            options={tenantSaleContinueOpts}
            valueType='string'
            inline={false}
            name='tenancyCont'
            parentPath={fullPath}
            myPath='tenancyCont'
          />
          </div>
          {tenant.tenancyCont === SaleTenancyContinuing.Yes && <div className='mt-2'>
          Upon sale, the Vendor or Vendor's representative will advise the tenant of the Purchaser's name and new rent payment details, at least 14 days before Settlement.
          </div>}
          {tenant.tenancyCont === SaleTenancyContinuing.No && <div className='mt-2'>
            If the tenancy agreement has not been effectively terminated already, the landlord will terminate the tenancy agreement and provide vacant possession to the Purchaser at Settlement.
          </div>}
          {tenant.tenancyCont === SaleTenancyContinuing.Unsure && <div className='mt-2'>
            The tenancy agreement may continue after Settlement, depending on negotiations with the Purchaser.
          </div>}
        </>}

      </>}
    </div>
  </div>;
}