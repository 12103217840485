import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { ReactNode, useContext, useMemo } from 'react';
import { ValidationDefnType } from '@property-folders/contract/yjs-schema/model';
import * as _ from 'lodash';
import { NetStateWriteContext } from '../dragged-components/NetStateContext';
import { signingValidationRules, fieldGroups } from '@property-folders/contract/yjs-schema/model/form/signing';
import { propertyFolderSigning } from '@property-folders/contract/yjs-schema/model/field';
import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormContext } from '../context/FormContext';
import { YjsDocContext } from '../context/YjsDocContext';
import { sortObj } from '@property-folders/contract/yjs-schema/model/form';

export function getDefaultReportMissing(formName: string, baseDefn?: ValidationDefnType) {
  if (!baseDefn || !['LOCAL', 'DEV', 'STABLE'].includes(import.meta.env?.VITE_DEPLOY_ENVIRONMENT || 'LOCAL')) {
    return () => { /* noop */ };
  }

  const seen = new Set<string>();

  let data = {};
  const printFn = _.debounce(() => {
    console.warn(`suggest updating the following definition for ${formName}...`);
    console.warn(JSON.stringify(data));
    console.warn(JSON.stringify(sortObj(_.merge(data, baseDefn)), null, '  ').replace(/"([^"]+)":/g, '$1:'));
  }, 1000);
  return (defn: ValidationDefnType) => {
    const key = JSON.stringify(defn);
    if (seen.has(key)) {
      return;
    }
    seen.add(key);
    data = _.merge(data, defn);
    printFn();
  };
}

export function SetupNetStateWritingYjsDocContext({
  children,
  ydoc,
  awareness,
  docName,
  transactionRootKey,
  transactionMetaRootKey
}: {
  children: ReactNode,
  ydoc: Y.Doc,
  awareness: Awareness,
  docName: string,
  transactionRootKey: string
  transactionMetaRootKey: string
}) {
  const { addPath, removePath } = useContext(NetStateWriteContext);
  const staticProviderValue = useMemo<YjsDocContextType>(()=>({
    ydoc,
    awareness,
    docName,
    transactionRootKey,
    transactionMetaRootKey,
    clearDeclareDebounce: removePath,
    declareDebounce: addPath
  }), [ydoc, docName, awareness, transactionRootKey]);

  return (
    <YjsDocContext.Provider value={staticProviderValue}>
      {children}
    </YjsDocContext.Provider>
  );
}

export function FormContextSigningOverride({ children }: {children: ReactNode}) {
  const baseContext = useContext(FormContext);
  const replacementFormContextValue = useMemo<FormContextType>(() => ({
    ...baseContext,
    formRules: signingValidationRules,
    transactionRules: propertyFolderSigning,
    fieldGroups
  }), []);

  return <FormContext.Provider value={replacementFormContextValue}>
    {children}
  </FormContext.Provider>;
}

/**
 * Set up a form context if a valid one doesn't already exist.
 * If a valid one does exist, use that instead
 */
export function FormContextSetup({ children, formId, base }: { children: ReactNode, formId: string, base: Omit<FormContextType, 'formId'> }) {
  const existing = useContext(FormContext);
  const formContext = useMemo(() => {
    return existing.formId
      // e.g. variation
      ? existing
      : {
        ...base,
        formId
      };
  }, [existing, formId, base]);

  return <FormContext.Provider value={formContext}>
    {children}
  </FormContext.Provider>;
}
