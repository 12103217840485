import { getProxyEmail, getProxyWithOriginalName } from '@property-folders/common/util/dataExtract';
import { FormUtil } from '@property-folders/common/util/form';
import { mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { FormInstance, FormSigningState, SigningStates } from '@property-folders/contract';
import { WrField } from '../dragged-components/form/CommonComponentWrappers';
import { Button, Modal } from 'react-bootstrap';
import { ReactElement, useMemo } from 'react';

export function VoidSigningModal(props:{
  show: boolean,
  onHide: ()=>void,
  onConfirm: ()=>void,
  formInstance: FormInstance | (()=>FormInstance),
  title?: string,
  confirmButtonText?: string
  ydocForceKey?: string
} & ({hasSignatures: boolean} | {
  bodyText: ReactElement
})) {
  const { formInstance, title, show, confirmButtonText, ydocForceKey } = props;

  const instance = useMemo(()=>{
    if (typeof formInstance === 'function') {
      return formInstance();
    }
    return formInstance;
  }, [show]);
  const showVoidParties = SigningStates.has(instance.signing?.state??FormSigningState.None);
  const instancePath = normalisePathToStr(FormUtil.generateFormInstancePath(instance.formCode, instance.id));
  return <Modal show={props.show} onHide={props.onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title ?? 'Void signing'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {'bodyText' in props
        ? props.bodyText
        : <p>{props.hasSignatures
          ? 'Voiding the signing session will remove all signatures. Are you sure you wish to void the signing session?'
          : 'This will cancel the signing session for all parties.'
        }</p>
      }
      {showVoidParties && <>
        <p>
          Notify parties that the signing session has been voided:
        </p>
        <div>
          {instance.signing?.parties?.map(party => {
            const isInitiator = instance.signing?.sessionInitiator?.id === party.snapshot?.linkedSalespersonId;
            const email = getProxyEmail(party);
            const missingEmail = !email;
            const partyName = `${getProxyWithOriginalName(party)}${isInitiator ? ' (Initiator)' : missingEmail ? ' (No email address)' : ''}`;
            return <WrField.BoolCheck
              noAwareness={true}
              disabled={missingEmail || isInitiator}
              overrideChecked={missingEmail ? false : undefined}
              nullishIsFalse={true}
              bindToMetaKey={ydocForceKey?false:true}
              ydocForceKey={ydocForceKey}
              parentPath={normalisePathToStr(mergePaths(instancePath, 'signing.parties', `[${party.id}]`))}
              name={`[${party.id}].noVoidNotify`}
              myPath='noVoidNotify'
              inverter={true}
              label={partyName}
            />;
          })}
        </div>
      </>}
    </Modal.Body>
    <Modal.Footer>

      <Button variant="outline-secondary" onClick={props.onHide}>
        Cancel
      </Button>
      <Button variant="danger" onClick={()=>{props.onConfirm(); props.onHide();}}>
        {confirmButtonText??'Void'}
      </Button>
    </Modal.Footer>
  </Modal>;
}
